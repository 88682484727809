import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import BlockContent from '@sanity/block-content-to-react'
import RichText from '~components/RichText'

const TeamMembers = ({ className, content }) => {
	return (	
		<Wrap className={className}>
			{content?.memberLeft &&
				<Person>
					<ImageBorder>
						<Image aspectRatio={0.8} image={content?.memberLeft?.image} />
					</ImageBorder>
					<TextWrap>
						<Heading>{content.memberLeft?.title}</Heading>
						<Text className='sm'>
							<RichText content={content?.memberLeft?.text}/>
						</Text>
					</TextWrap>
				</Person>
			}
			{content?.memberRight &&
				<Person>
					<ImageBorder>
						<Image aspectRatio={0.8} image={content?.memberRight?.image} />
					</ImageBorder>
					<TextWrap>
						<Heading>{content.memberRight?.title}</Heading>
						<Text className='sm'>
							<RichText content={content?.memberRight?.text}/>
						</Text>
					</TextWrap>
				</Person>
			}
		</Wrap>
	)
}

const Wrap = styled.div`
	margin-bottom: var(--xl);
	/* display: grid; */
	
`

const Person = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 30px;
	grid-row-gap: var(--l);
	margin-bottom: var(--xxxl);
	margin-top: var(--xxxl);
	${mobile}{
		display: block;
		grid-column-gap: 20px;
		grid-template-columns: 1fr;
	}
`

const ImageBorder = styled.div`
	border: 6px solid var(--orange);
	border-radius: 8px;
	overflow: hidden;
	margin-bottom: auto;
	grid-column: span 5;
	${mobile}{
		max-width: 350px;
		margin-left: auto;
		margin-right: auto;
		width: 90%;
		margin-bottom: var(--l)
	}
`

const TextWrap = styled.div`
	grid-column: span 7;
`

const Text = styled.div`
	/* margin-top: var(--m); */
	p:last-child{
		margin-bottom: 0;
	}
`
const Heading = styled.h5`
	margin-bottom: var(--m) !important;
	margin-top: 0 !important;
`
TeamMembers.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default TeamMembers